import * as React from 'react'
import { cn } from '#utils/utils.js'
import { Icon, type IconName } from './Icon'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: IconName
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, icon, type = 'text', ...props }, ref) => {
    return (
      <div className="relative w-full">
        <input
          type={type}
          className={cn(
            'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/70 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-muted focus-visible:border-primary transition-colors duration-300',
            icon && 'peer pl-9',
            className
          )}
          ref={ref}
          {...props}
        />
        {icon ? (
          <div className="absolute left-0 top-0 flex h-full w-10 items-center justify-center text-muted-foreground/50 peer-focus:text-brand">
            <Icon name={icon} size="sm" />
          </div>
        ) : null}
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
